<template>
	<div class="coupon">
		<div class="my-title clearfix">
			<span class="">我的优惠券</span>
		</div>
		<el-tabs class="tabs-line" v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="未使用" name="isUsable"></el-tab-pane>
			<el-tab-pane label="已使用" name="isUse"></el-tab-pane>
			<el-tab-pane label="已过期" name="isExpire"></el-tab-pane>
		</el-tabs>
		<div class="coupon-list clearfix">
			<el-empty :image-size="50" description="暂无优惠券" v-if="list.data&&list.data.length==0"></el-empty>
			<ul>
				<li v-for="(item,index) in list.data" :key="index" :class="{'gray':!item.state.value}">
					<div class="left">
						<p class="price" v-if="item.coupon_type == CouponTypeEnum.FULL_DISCOUNT.value">
							<em>￥</em><span>{{ item.reduce_price }}</span>
						</p>
						<p class="price" v-if="item.coupon_type == CouponTypeEnum.DISCOUNT.value">
							<span>{{ item.discount }}折</span>
						</p>
						<p class="tips">满{{ item.min_price }}元可用</p>
					</div>
					<div class="right">
						<p class="title">{{ item.name }}</p>
						<p class="tips">
							<em class="time">{{ item.start_time }}~{{ item.end_time }}</em>
							<span class="receive">{{ item.state.text }}</span>
						</p>
					</div>
					<div class="style">
						<div class="dot dot1"></div>
						<div class="line"></div>
						<div class="dot dot2"></div>
					</div>
					<div class="corner-marker">{{ CouponTypeEnum[item.coupon_type].name }}</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import * as MyCouponApi from '@/api/myCoupon'
	import { CouponTypeEnum } from '@/common/enum/coupon'
	export default {
		data() {
			return {
				// 枚举类
				CouponTypeEnum,
				// 优惠券列表数据
				list: [],
				activeName: 'isUsable',
			}
		},
		created() {
			this.getList()
		},
		methods: {
			getList(){
				let app = this
				MyCouponApi.list({ dataType: app.activeName, page: 1 }, { load: false })
				  .then(result => {
				    // 合并新数据
				    app.list = result.data.list
				  })
			},
			handleClick(tab, event) {
				console.log(tab.name);
				this.activeName = tab.name
				this.getList()
			}
		}
	}
</script>

<style lang="scss">
	@import "../../assets/css/coupon.scss";
</style>
